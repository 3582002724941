<template>
    <v-container>
      <v-layout justify-center align-center>
        <v-flex xs12>
            <v-card class="mt-12 pa-4">
              <v-card-title class="red darken-4 headline">
                <span class="title white--text">Pagina no encontrada</span>
              </v-card-title>
              <v-card-text class="mt-4 mb-4 text--primary">
                <p class="title mt-4 text-h6">La pagina que estas buscando no existe.</p>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="goBack" color="primary">
                    <v-icon left>fas fa-arrow-left</v-icon>
                    Regresar
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
    methods: {
      goBack() {
        this.$router.go(-1); // Go back one step in the browser history
      }
    }
  };
  </script>